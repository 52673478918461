<template>
  <div class="relative flex flex-col">
    <ClientOnly>
      <template #fallback>
        <SkeletonsHeroBanner></SkeletonsHeroBanner>
      </template>
      <PageBuilder
        :layout="layout"
        v-if="!pending"
        :show-back-btn-always="true"
      ></PageBuilder>
    </ClientOnly>
  </div>
</template>

<script setup>
const route = useRoute();

// Fetch Theme page
const { data: layout, pending } = await useAsyncData(
  "theme-page",
  async () => {
    return await fetchLayout("/api/biz/config/v1/carousels", {
      external_category_id: route.params.theme,
    });
  },
  { server: false }
);

useHead({
  title: layout.value?.title,
  meta: [
    {
      name: "og:image",
      content: layout.value?.resourceBaseHost + layout.value?.backgroundImg,
    },
  ],
});

onBeforeMount(async () => {
  await useFmaSdk().logPageNav(usePageId().ADULT__THEMATIC);
});

onMounted(async () => {
  await useFmaSdk().loaded();
});

onBeforeUnmount(() => {
  useFmaSdk().terminate();
});
</script>

<style lang="scss" scoped></style>
